export const weatherStations = [
    {
        name: "Prempeh Assembly Hall",
        latitude: 6.6938011,
        longitude: -1.6147393,
        code: "TA00842"
    },
    {
        name: "WESCO",
        latitude: 6.7146553,
        longitude: -1.6234614,
        code: "TA00847"
    },
    {
        name: "Kumasi Academy",
        latitude: 6.7087753,
        longitude: -1.5707259,
        code: "TA00843"
    },
    {
        name: "Garden City University",
        latitude: 6.7398743,
        longitude: -1.566275,
        code: "TA00844"
    },
    {
        name: "Duase",
        latitude: 6.7321502,
        longitude: -1.5731357,
        code: "TA00845"
    }
]