import { Route, Routes } from 'react-router-dom';
import { Home } from './Pages/Home/Home';
import { Hydrological } from './Pages/Hydrological/Hydrological';
import { Login } from './Pages/Login/Login';
import { Meteorological } from './Pages/Meteorological/Meteorological';
import { RainfallForecast } from './Pages/RainfallForecasts/RainfallForecasts';
import { ProtectedRoutes } from './utils/ProtectedRoutes';

function App() {
	return (
		<Routes>
			<Route element={<ProtectedRoutes />}>
				<Route element={<Home />} path='/' />
				<Route element={<Meteorological />} path='/meteorological' />
				<Route element={<Hydrological />} path='/hydrological' />
				<Route element={<RainfallForecast />} path='/rainfall-forecast' />
			</Route>
			<Route path='/login' element={<Login />} />
		</Routes>
	);
}

export default App;
