export const Help = () => {
    return (<>
        <div id="fill-dark-modal" className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="fill-dark-modalLabel" aria-hidden="true">
            <div className="modal-dialog" style={{ marginLeft: "25%", marginRight: "25%" }}>
                <div className="modal-content modal-filled bg-dark" style={{ width: "50vw" }}>
                    <div className="modal-header">
                        <h4 className="modal-title" id="fill-dark-modalLabel">Welcome to the FEWS Dashboard! </h4>
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-hidden="true"></button>
                    </div>


                    <div className="modal-body overflow-auto" style={{ maxHeight: "75vh", scrollbarWidth: "thin", scrollbarColor: "white rgba(var(--ct-dark-rgb),var(--ct-bg-opacity))" }}>
                        <span>This user guide will help you familiarize yourself with the dashboard's features and functionalities, allowing you to navigate and utilize it effectively.</span>
                        <h4 className="mb-0">Overview</h4>
                        <div>
                            <div className="d-flex">
                                <i className="ri-checkbox-indeterminate-fill"></i>
                                <p className="ps-1 m-0">Navigation Bar</p>
                            </div>
                            <ul className="list-group">
                                <li className="list-group-item py-0 text-white-50"><i className="ri-subtract-fill"></i> Active user information (User Name, Email, Profile Picture)</li>
                                <li className="list-group-item py-0 text-white-50"><i className="ri-subtract-fill"></i> On smaller screens, partners' logo and a dropdown button is displayed</li>
                            </ul>
                        </div>

                        <div>
                            <div className="d-flex">
                                <i className="ri-checkbox-indeterminate-fill"></i>
                                <p className="ps-1 m-0">Left Side Panel</p>
                            </div>
                            <ul className="list-group">
                                <li className="list-group-item py-0 text-white-50"><i className="ri-subtract-fill"></i> Partners logos</li>
                                <li className="list-group-item py-0 text-white-50"><i className="ri-subtract-fill"></i> Measurement session</li>
                                <li className="list-group-item py-0 text-white-50"><i className="ri-subtract-fill"></i> Forecasts session</li>
                                <li className="list-group-item py-0 text-white-50"><i className="ri-subtract-fill"></i> Data period selection</li>
                                <li className="list-group-item py-0 text-white-50"><i className="ri-subtract-fill"></i> Help button</li>
                            </ul>
                        </div>

                        <div>
                            <div className="d-flex">
                                <i className="ri-checkbox-indeterminate-fill"></i>
                                <p className="ps-1 m-0">Content session</p>
                            </div>
                            <ul className="list-group">
                                <li className="list-group-item py-0 text-white-50"><i className="ri-subtract-fill"></i> Map to display the locations</li>
                                <li className="list-group-item py-0 text-white-50"><i className="ri-subtract-fill"></i> Legend</li>
                                <li className="list-group-item py-0 text-white-50"><i className="ri-subtract-fill"></i> Graph</li>
                            </ul>
                        </div>

                        <div className="d-flex">
                            <i className="ri-checkbox-indeterminate-fill"></i>
                            <p className="ps-1 m-0">Markers: <span className="text-white-50">They represent the specific location where the data is been recorded.</span></p>
                        </div>

                        <h4 className="mb-0">Usage</h4>
                        <span className="text-white-50">The dashboard contains sessions of data sources, that is <strong style={{ color: "rgba(var(--ct-link-color-rgb), var(--ct-link-opacity, 1))" }}>Measurements</strong> and <strong style={{ color: "rgba(var(--ct-link-color-rgb), var(--ct-link-opacity, 1))" }}>Forecasts</strong>, in which under each session have sub-sessions of specific data.</span>
                        <div className="pt-1">
                            <div className="d-flex">
                                <i className="ri-radio-button-line"></i>
                                <p className="ps-1 m-0"> Measurements </p>
                            </div>
                            <div className="accordion ms-3" id="accordionMeasurementsPanel">
                                <div className="accordion-item border-0 border-bottom">
                                    <h2 className="accordion-header" id="meteo-heading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#meteo-collapse" aria-expanded="true" aria-controls="meteo-collapse">
                                            Meteorological
                                        </button>
                                    </h2>
                                    <div id="meteo-collapse" className="accordion-collapse collapse" aria-labelledby="meteo-heading" data-bs-parent="#accordionMeasurementsPanel">
                                        <div className="accordion-body text-white-50">
                                            <p>The meteorological sub-session consist of the weather measurements of the variables precipitation, temperature, relative humidity, solar radiation and wind speed of the specified location.
                                            </p>
                                            <ul className="list-group list-group-numbered">
                                                <li className="d-flex">
                                                    <i className="ri-subtract-fill"></i>
                                                    <p>When clicked, weather markers are displayed on the map.</p>
                                                </li>
                                                <li className="d-flex">
                                                    <i className="ri-subtract-fill"></i>
                                                    <p>The markers can be in different color depending on the precipitation value.</p>
                                                </li>
                                                <li className="d-flex">
                                                    <i className="ri-subtract-fill"></i>
                                                    <p>When a marker is clicked, a graph is shown containing the measurements.</p>
                                                </li>
                                                <li className="d-flex">
                                                    <i className="ri-subtract-fill"></i>
                                                    <p>On default, precipitation measurement graph is displayed and it can be changed to a different weather variable graph by selecting the variable from the left side of the graph.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 border-bottom">
                                    <h2 className="accordion-header" id="hydro-heading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#hydro-collapse" aria-expanded="false" aria-controls="hydro-collapse">
                                            Hydrological
                                        </button>
                                    </h2>
                                    <div id="hydro-collapse" className="accordion-collapse collapse" aria-labelledby="hydro-heading" data-bs-parent="#accordionMeasurementsPanel">
                                        <div className="accordion-body text-white-50">
                                            <p>The hydrological sub-session consist of the water level measurements of the specified location.</p>
                                            <ul className="list-group list-group-numbered">
                                                <li className="d-flex">
                                                    <i className="ri-subtract-fill"></i>
                                                    <p>When clicked, hydrological markers are displayed on the map.</p>
                                                </li>
                                                <li className="d-flex">
                                                    <i className="ri-subtract-fill"></i>
                                                    <p>The markers can be of different colors depending on the water level's value.</p>
                                                </li>
                                                <li className="d-flex">
                                                    <i className="ri-subtract-fill"></i>
                                                    <p>When a marker is clicked, a graph is displayed containing the water level measurements of the location.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="cml-heading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cml-collapse" aria-expanded="false" aria-controls="cml-collapse">
                                            Commercial Microwave Links
                                        </button>
                                    </h2>
                                    <div id="cml-collapse" className="accordion-collapse collapse" aria-labelledby="cml-heading" data-bs-parent="#accordionMeasurementsPanel">
                                        <div className="accordion-body">
                                            <p className="text-white-50">... Temporarily disabled ...</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-1">
                            <div className="d-flex">
                                <i className="ri-radio-button-line"></i>
                                <p className="ps-1 m-0"> Forecasts </p>
                            </div>
                            <div className="accordion ms-3" id="accordionForecastsPanel">

                                <div className="accordion-item border-0 border-bottom">
                                    <h2 className="accordion-header" id="rainfall-heading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#rainfall-collapse" aria-expanded="true" aria-controls="rainfall-collapse">
                                            Rainfall
                                        </button>
                                    </h2>
                                    <div id="rainfall-collapse" className="accordion-collapse collapse" aria-labelledby="rainfall-heading" data-bs-parent="#accordionForecastsPanel">
                                        <div className="accordion-body text-white-50">
                                            <p>The rainfall sub-session is made up of a 6-day rainfall forecasts starting today.</p>
                                            <ul className="list-group list-group-numbered">
                                                <li className="d-flex">
                                                    <i className="ri-subtract-fill"></i>
                                                    <p>When clicked, markers are displayed on the map for all locations.</p>
                                                </li>
                                                <li className="d-flex">
                                                    <i className="ri-subtract-fill"></i>
                                                    <p>The markers can be of different colors depending on the precipitation value.</p>
                                                </li>
                                                <li className="d-flex">
                                                    <i className="ri-subtract-fill"></i>
                                                    <p>When a marker is clicked, a graph is displayed containing the 6-day (starting today) rainfall forecast for that location.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="flood-heading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flood-collapse" aria-expanded="true" aria-controls="flood-collapse">
                                            Flood
                                        </button>
                                    </h2>
                                    <div id="flood-collapse" className="accordion-collapse collapse" aria-labelledby="flood-heading" data-bs-parent="#accordionForecastsPanel">
                                        <div className="accordion-body text-white-50">
                                            <p>The flood sub-session is also made up of another sub-sessions for predicting water levels and displaying flood maps. </p>
                                            <ul className="list-group">
                                                <li className="d-flex">
                                                    <p className="ps-1 m-0"><i className="ri-subtract-fill"></i> Water Levels: <span className="text-white-50">Temporarily disabled</span></p>
                                                </li>
                                                <li className="d-flex">
                                                    <p className="ps-1 m-0"><i className="ri-subtract-fill"></i> Flooded Areas: <span className="text-white-50">Temporarily disabled</span></p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="d-flex pt-1">
                            <i className="ri-radio-button-line"></i>
                            <p className="ps-1 m-0">Datepicker <small>(month/day/year)</small>: <span className="text-white-50">Choose the last recorded date of the data to be displayed.</span></p>
                        </div>

                        <div>
                            <div className="d-flex">
                                <i className="ri-radio-button-line"></i>
                                <p className="ps-1 m-0">Legend</p>
                            </div>
                            <ul className="list-group">
                                <li className="list-group-item py-0 text-white-50">The legend explains the color code of the markers. The legend displays the thresholds based on the session in which data is been displayed.</li>
                                <li className="list-group-item py-0 text-white-50"><i className="ri-subtract-fill"></i> <span style={{ color: "red" }}>Red marker</span> is a danger zone.</li>
                                <li className="list-group-item py-0 text-white-50"><i className="ri-subtract-fill"></i> <span style={{ color: "orange" }}>orange marker</span> is an awareness zone.</li>
                                <li className="list-group-item py-0 text-white-50"><i className="ri-subtract-fill"></i> <span style={{ color: "yellow" }}>Yellow marker</span> is a normal zone.</li>
                                <li className="list-group-item py-0 text-white-50"><i className="ri-subtract-fill"></i> <span style={{ color: "cyan" }}>Light blue marker</span> is below threshold.</li>
                                <li className="list-group-item py-0 text-white-50"><i className="ri-subtract-fill"></i> <span style={{ color: "black" }}>Black marker</span> represents no data.</li>
                            </ul>
                        </div>

                    </div>

                    <div className="modal-footer justify-content-start" style={{ backgroundColor: "rgba(255,255,255,.07)" }}>
                        <span>To report any problem or challenges, kindly <a href="mailto: info@tahmo.org"> <strong>SEND</strong></a> us an email.</span>
                    </div>
                </div>
            </div>
        </div>

    </>)
}