import { ApexOptions } from "apexcharts";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import "./Offcanvas.css";

type OffcanvasProps = {
    title: string
    series: ApexAxisChartSeries
    setSeries?: Dispatch<SetStateAction<ApexAxisChartSeries>>
    seriesData?: any
    isBar: string
    setIsBar: Dispatch<SetStateAction<string>>
}


const units: any = {
    pr: "[mm]",
    te: "[degrees Celsius]",
    ra: "W/m2",
    rh: "[-]",
    ws: "[m/s]",
}


export const Offcanvas = ({ title, series, setSeries, seriesData, isBar, setIsBar }: OffcanvasProps) => {

    const [typee, setTypee] = useState("")
    const [unit, setUnit] = useState("[mm]")

    useEffect(() => {
        setTypee(window.location.href.split('/')[3]);
    }, [])

    useEffect(() => {
    }, [isBar, unit])

    const options: ApexOptions = {
        title: {
            text: title,
            align: 'center',
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#263238'
            },
        },
        fill: {
            opacity: 0.2
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 1
        },
        tooltip: {
            shared: isBar === "area" ? true : false,
            intersect: isBar === "area" ? false : true
        },
        yaxis: {
            labels: {
                formatter: (value) => { return value.toFixed(2) + "" }
            },
            decimalsInFloat: 0,
            title: {
                text: typee === "hydrological" ? "[meters]" : typee === "rainfall-forecast" ? "[mm]" : unit,
                rotate: -90,

            }
        },
        xaxis: {
            type: "datetime",
            labels: {
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                },
            }
        }
    }

    const chooseVarData = (varr: string) => {
        const selectVar = document.getElementById(varr) as HTMLSelectElement;
        setUnit(units[selectVar.value])
        if (setSeries !== undefined) {
            setSeries(seriesData[selectVar.value])
            if (seriesData[selectVar.value] === "pr") {
                setIsBar("bar")
            } else {
                setIsBar("area")
            }
        }
    }

    return (
        <div className="p-0 offcanvas offcanvas-bottom" data-bs-scroll="true" data-bs-backdrop="false" style={{ marginLeft: "var(--ct-leftbar-width)", height: "35vh", width: "calc(100vw - var(--ct-leftbar-width))" }} tabIndex={-1} id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">

            {typee === "meteorological" ? (
                <div className="offcanvas-header p-1 pe-3">
                    <select className="form-select m-2 mb-0 mt-0" style={{ width: "25%" }} onChange={() => chooseVarData("varr")} id="varr">
                        <option value="pr">Precipitation</option>
                        <option value="te">Temperature</option>
                        <option value="rh">Relative Humidity</option>
                        <option value="ra">Solar Radiation</option>
                        <option value="ws">Wind Speed</option>
                    </select>
                    <button id="close" type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
            ) : (
                <div className="offcanvas-header p-1 pe-3 justify-content-end">
                    <button id="close" type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
            )}
            <div className="offcanvas-body p-0">
                <div className="col-12" style={{ height: "90%" }}>
                    <Chart
                        options={options}
                        series={series}
                        width="98%"
                        height="100%"
                        className="apex-charts m-0"
                    />
                </div>
            </div>
        </div>
    )
}
