import { GeoJsonObject } from "geojson";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import { GeoJSON, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import basinData from '../../utils/AboaboBasin/Aboabo_Basin_GSMA.json';


type MapContainerComponentProp = {
    loading: boolean
    stations: {
        name: string
        latitude: number
        longitude: number
        code: string
    }[]
    offcanvasfunc: (obj: { title: string, code: string }) => void
    colorCheck: any
}


export const MapContainerComponent = ({ loading, stations, offcanvasfunc, colorCheck }: MapContainerComponentProp) => {
    const [geojsonFile, setGeoFile] = useState<GeoJsonObject | null>(null)

    useEffect(() => {
        setGeoFile(basinData as GeoJsonObject);
    }, [])

    return (
        <MapContainer center={[6.709327322997796, -1.559240192997633]} zoom={12}>
            <TileLayer
                attribution='Google Maps'
                url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
            />
            {loading ? (
                <div id="preloader" style={{ opacity: "0.9" }}>
                    <div id="status" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <span className="h3" > Loading </span>
                        < div className="bouncing-loader" >
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {stations.map((item, index) => {
                        let iconUrl;
                        try {
                            iconUrl = require(`../../utils/MarkerIcon/${colorCheck[item.code]}`);
                        } catch (err) {
                            if (item.code.startsWith("TH")) {
                                iconUrl = require("../../utils/MarkerIcon/rainfall_no_data.png");
                            } else {
                                iconUrl = require("../../utils/MarkerIcon/no_data.png");
                            }
                        }
                        const customIcon = new Icon({
                            iconUrl,
                        })
                        return (
                            <Marker key={index + item.code} position={[item.latitude, item.longitude]} icon={customIcon} eventHandlers={{
                                click: () => {
                                    const typee = window.location.href.split('/')[3]
                                    if (typee === "meteorological") {
                                        offcanvasfunc({ title: item.name, code: item.code })
                                    }

                                    if (typee === "hydrological") {
                                        offcanvasfunc({ title: `${item.name} - (Water Levels)`, code: item.code })
                                    }

                                    if (typee === "rainfall-forecast") {
                                        offcanvasfunc({ title: item.name + " - a six-day rainfall forecast starting today", code: item.code })
                                    }
                                }
                            }}>
                                <Popup>
                                    {item.name}
                                </Popup>
                            </Marker>
                        )
                    })}
                </>
            )}
            {geojsonFile && <GeoJSON data={geojsonFile} />}
        </MapContainer>
    )
}
